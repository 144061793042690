import { useState } from "react";
import CustomLineChart from "./CustomLineChart";
import './FinancialData.css';
import CustomTooltip from "../Components/CustomTooltip";
import OrLink from "./OrLink";
import { getDomainById } from "../helpers";
import domainIdLabelMapping from '../domainIdLabelMapping.json';
import CompanyXlsxDownloadButton from "./CompanyXlsxDownloadButton";
import MetricsBlock from "./MetricsBlock";
import { CircularProgress } from "@mui/material";

const FinancialDataBlock = (props) => {

    const [selectedItemId, setSelectedItemId] = useState({});

    const selectItem = (e, domainId) => {
        const newSelectedItems = selectedItemId;
        newSelectedItems[domainId] = e.target.value;
        setSelectedItemId({ ...newSelectedItems })
    }

    const renderGraphs = (yearData, domainId, domain) => {

        const mySelectedItemId = (domainId in selectedItemId && selectedItemId[domainId]) ? selectedItemId[domainId] : yearData[0].id;

        return (
            <div>
                <div className="styled-select">
                    <select onChange={e => selectItem(e, domainId)} value={mySelectedItemId}>

                        {yearData.map((item, idx) => (
                            <option key={idx} value={item.id}>{domain[item.id].label}</option>
                        ))}
                    </select>
                </div>

                <div className="collapsableDiv">

                    <p>{domain[mySelectedItemId].label}</p>

                    <div className="uncollapsedGraph">

                        {<div className="financialDataDiv">
                            <CustomLineChart data={yearData.find(item => item.id === mySelectedItemId).values.map((d) => { return { name: d.name, hodnota: d.value ? props.isMunicipality ? d.value / 1000 : d.value : "Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok." } })} />
                        </div>}

                    </div>
                </div>




            </div>
        )

    }

    if (!props.financialData) {
        return (
            <CircularProgress className="myProgressSpinner" />
        )
    }

    const formatter = Intl.NumberFormat('en-GB');

    return (

        <div className="graphsMain">

            {

                <div className="domainGraphDiv">

                    {props.financialData.reduce((totalLength, domain) => totalLength + Object.keys(domain.values).length, 0) > 0 ?
                        <CompanyXlsxDownloadButton
                            company={props.company}
                            data={props.financialData}
                        />
                        :
                        <div></div>
                    }

                    {props.financialData.map(domain => {

                        const yearData = mapFinancialData(domain.values);

                        const uniqueYears = getUniqueYears(yearData);

                        const domainItems = getDomainById(domain.id)
                        const domainKeys = Object.keys(domainItems);

                        yearData.sort((a, b) => domainKeys.indexOf(a.id) > domainKeys.indexOf(b.id) ? 1 : -1)


                        return (

                            (!yearData || yearData.length === 0) ?

                                <div>
                                    <div>
                                        <p>V nalezených dokumentech se nám nepodařilo spolehlivě detekovat data pro {domainIdLabelMapping[domain.id]} společnosti.</p>
                                        <p>Originály dokumentů si můžete stáhnout v sekci <b>Účetní závěrky</b> a data si ověřit.</p>
                                    </div>

                                </div>
                                :

                                <div>

                                    <div className="tableList">

                                        <h2 className="domainHeadline">{domainIdLabelMapping[domain.id]}</h2>

                                        {renderGraphs(yearData, domain.id, domainItems)}

                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <CustomTooltip title="Hodnota byla ověřena kontrolními součty finančních výkazů z daného roku anebo stejná hodnota byla nalezena i ve výkazech z dalšího roku."><div className="descriptionDot dotVerified"></div></CustomTooltip>
                                                        <CustomTooltip title="Hodnota byla sice nalezena ve finančních výkazech, ale nebylo možné ji ověřit kontrolním součtem nebo porovnat vůči dokumentu z dalšího roku."><div className="descriptionDot dotNotVerified"></div></CustomTooltip>
                                                        <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="descriptionDot greyDot"></div></CustomTooltip>
                                                    </th>
                                                    <th colSpan={uniqueYears.length}><div className="yearTh"><p>Rok</p>
                                                        <p className="termsDiv">Data jsou pravděpodobně tisicích.</p>
                                                    </div></th>
                                                </tr>
                                                <tr>
                                                    <th className="leftAlignedColumn">č. řádku</th>
                                                    <th className="leftAlignedColumn">Značení</th>
                                                    <th className="leftAlignedColumn">Položka</th>
                                                    {uniqueYears.map(year => <th>{year}</th>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {yearData.map(item => {
                                                    const values = uniqueYears.map(
                                                        year => item.values.filter(v => v.name === year).map(v =>
                                                            v.value !== null && !v.value_sources.includes("not_found") ?
                                                                v.value_sources.includes("x_validate") || v.value_sources.includes("x_document_validate") ?
                                                                    <span className="verified">{formatter.format(props.isMunicipality ? (v.value / 1000).toFixed(0) : v.value)}</span>
                                                                    : <span className="not-verified">{formatter.format(props.isMunicipality ? (v.value / 1000).toFixed(0) : v.value)}</span>
                                                                : <div className="dot"></div>)[0]
                                                    )
                                                    return (
                                                        <tr className={!domainItems[item.id].offset ? "importantTableRow" : ''}>
                                                            <td className="leftAlignedColumn">{domainItems[item.id].row_id}</td>
                                                            <td>
                                                                {domainItems[item.id].marking} <b className="expenseCategory">{domainItems[item.id].category === 'income' ? '(+)' : domainItems[item.id].category === 'expense' ? '(-)' : null}</b>
                                                            </td>
                                                            <td className="leftAlignedColumn">
                                                                {domainItems[item.id].description ?
                                                                <CustomTooltip title={domainItems[item.id].description }>
                                                                    <span>{domainItems[item.id].label}</span>
                                                                </CustomTooltip> : domainItems[item.id].label }
                                                            </td>
                                                            {values.map(v => <td>{v}</td>)}
                                                        </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>

                                </div>)
                    }
                    )}
                </div>}


            {
                props.orDocumentsUrl ? <OrLink url={props.orDocumentsUrl} /> : null
            }

            <MetricsBlock yearData={props.financialData} user={props.user} unlockFailed={props.showLimitError} />

        </div>


        // TODO: Show a table with all the years
    )

}

function mapFinancialData(financialData) {

    const mapping = Object.keys(financialData).map((key, index) => {

        const sorted = financialData[key].sort((a, b) => (a.year > b.year) ? 1 : -1)

        const data = sorted.map(item => ({ "name": item.year, "value": item.value, "value_sources": item.value_sources }))

        return { id: key, values: data }
    })

    return mapping
}

function getUniqueYears(items) {

    const uniqueYears = [];
    items.map(item => item.values.map(value => {
        if (uniqueYears.indexOf(value.name) === -1) {
            uniqueYears.push(value.name)
        }
    }))

    return uniqueYears;

}

export default FinancialDataBlock;