import React from "react";
import domainIdLabelMapping from '../domainIdLabelMapping.json';
import "./DetectedDomainHeader.css";
import { Switch } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';


class DetectedDomainHeader extends React.Component {

    renderYear = (year) => {
        if (year) return <p>Detekovaný rok: {year}</p>
    }

    render() {
        return (
            <div className="domainHeaderDiv">
                <h1>{domainIdLabelMapping[this.props.matchedDomainId]}</h1>
                {this.renderYear(this.props.detectedYear)}
            </div>

        )
    }

}

export default DetectedDomainHeader;