import React from "react";
import CustomTooltip from "../Components/CustomTooltip";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getDomainById } from "../helpers";
import './StatementTable.css';

class StatementTable extends React.Component {

    getValue = (values, value_type) => values.filter(item => item.id === value_type)

    getConfidence = (validationSources) => {

        var xValidate = false
        if (validationSources.indexOf('x_validate') > -1) {
            xValidate = true
        }

        if (xValidate) {

            return (
                <div>
                    <CustomTooltip title="Hodnota byla ověřena aspoň jedním součtovým pravidlem, které obsahuje aspoň jednu hodnotu nelezenou přímo v dokumentu. Hodnoty, které nejsou nalezeny budou považovány za nulové." arrow>
                        <CheckBoxIcon style={{ fontSize: 30, color: 'green' }} />
                    </CustomTooltip>
                </div>
            )
        }
        else {
            return null
        }
    }

    render() {

        const domain = getDomainById(this.props.domainId);

        const domainKeys = Object.keys(domain);

        this.props.data.sort((a, b) => domainKeys.indexOf(a.id) > domainKeys.indexOf(b.id) ? 1 : -1)

        const domainDefinition = {
            "vykaz_zisku_ztrat": [{ "id": "current_value", "label": "Hlavní rok" }, { "id": "previous_value", "label": "Předchozí rok" }],
            "aktiva": [{ "id": "current_brutto", "label": "Brutto" }, { "id": "current_korekce", "label": "Korekce" }, { "id": "current_netto", "label": "Netto" }, { "id": "previous_netto", "label": "Minulé Netto" }],
            "pasiva": [{ "id": "current_value", "label": "Hlavní rok" }, { "id": "previous_value", "label": "Předchozí rok" }]
        }

        let monetaryOrder = 'tisicích Kč';
        if (this.props.monetaryOrder && "numeric_order" in this.props.monetaryOrder) {

            if (this.props.monetaryOrder["numeric_order"] == "units") {
                monetaryOrder = 'jednotkách Kč'
            }
            if (this.props.monetaryOrder["numeric_order"] == "millions") {
                monetaryOrder = "milionech Kč"
            }
        }

        const rowData = this.props.data.map((item, idx) => (
            <tr className={!domain[item.id].offset ? "importantTableRow" : ''} key={idx}>

                <td className="leftAlignedColumn">{domain[item.id].row_id}</td>
                <td className='leftAlignedColumn'>
                    {domain[item.id].marking} <b className="expenseCategory">{domain[item.id].category === 'income' ? '(+)' : domain[item.id].category === 'expense' ? '(-)' : null}</b>
                </td>

                <td className="leftAlignedColumn">
                    
                {item.text ?
                        <CustomTooltip title={
                            <React.Fragment>
                                <p><b>Nalezený text v dokumentu: </b>{item.text}</p>
                                {domain[item.id].description ? <p><b>Popis: </b>{domain[item.id].description}</p> : null}
                            </React.Fragment>}
                        >
                            <span>{domain[item.id].label}</span>
                        </CustomTooltip> :
                        <CustomTooltip title={
                            <React.Fragment>
                                <p><b>Položka nebyla v dokumentu nalezena</b></p>
                                {domain[item.id].description ? <p><b>Popis: </b>{domain[item.id].description}</p> : null}
                            </React.Fragment>}
                        >
                            <span>{domain[item.id].label}</span>
                        </CustomTooltip>
                    }

                </td>

                {domainDefinition[this.props.domainId].map(value_definition =>
                    <td>

                        {
                            this.getValue(item.values, value_definition.id)[0].value !== null && !this.getValue(item.values, value_definition.id)[0].value_sources.includes("not_found") ?
                                this.getValue(item.values, value_definition.id)[0].value_sources.includes("x_validate") || this.getValue(item.values, value_definition.id)[0].value_sources.includes("x_document_validate") ?
                                    <CustomTooltip title="Hodnota byla ověřena kontrolními součty finančních výkazů z daného roku anebo stejná hodnota byla nalezena i ve výkazech z dalšího roku."><span className="verified">{this.getValue(item.values, value_definition.id)[0].value}</span></CustomTooltip>
                                    : <CustomTooltip title="Hodnota byla sice nalezena ve finančních výkazech, ale nebylo možné ji ověřit kontrolním součtem nebo porovnat vůči dokumentu z dalšího roku."><span className="not-verified">{this.getValue(item.values, value_definition.id)[0].value}</span></CustomTooltip>
                                : <CustomTooltip title="Z detekovaných dat nebylo možné spolehlivě určit hodnotu položky pro daný rok."><div className="dot"></div></CustomTooltip>
                        }
                    </td>
                )}

            </tr>

        ));


        return (

            <div className="tableList">
                {rowData.length > 0 ?
                    <table>

                        <thead>
                            <tr key="table_list_head">
                                <th className="leftAlignedColumn">č. řádku</th>
                                <th className="leftAlignedColumn">Značení</th>
                                <th><div className="yearTh"><p>Položka</p>
                                    <p className="termsDiv">Data jsou pravděpodobně v {monetaryOrder}.</p>
                                </div></th>
                                {domainDefinition[this.props.domainId].map(value_definition =>
                                    <th>{value_definition.label}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {rowData}
                        </tbody>
                    </table> : null}
            </div>
        )
    }
}

export default StatementTable;